import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ConfigurationService } from '../../core/services/configuration.service';
import { handleMissingServerError } from '../../core/utils/http-response-handlers';
import { ApiFileForPreviewRequest, BfdRequest, BfdResponse } from './file.types';

@Injectable({
    providedIn: 'root',
})
export class BfdFileExplorerService {
    private serverName: string = 'BFD File Explorer API';
    private FILES_ENDPOINT = 'files';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    public get(request: BfdRequest): Observable<BfdResponse> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bfdFileExplorerUrl,
            this.FILES_ENDPOINT,
        ].join('/');

        return this.http
            .post(requestUrl, request, { headers: { 'Content-Type': 'application/json' }, observe: 'response' })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public download(names: string[]): Observable<Response> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bfdFileExplorerUrl,
            this.FILES_ENDPOINT,
            'download',
        ].join('/');

        return this.http
            .post(requestUrl, names, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }

    public getContents(requestFile: ApiFileForPreviewRequest): Observable<HttpResponse<Blob>> {
        const requestUrl = [
            this.configurationService.envConfig.getValue().bfdFileExplorerUrl,
            this.FILES_ENDPOINT,
            'view',
        ].join('/');

        return this.http
            .post(requestUrl, requestFile, {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(catchError(handleMissingServerError(this.serverName)));
    }
}
