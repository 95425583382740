import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalConfigData, ModalResult } from '../brokerage-accounts/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityRecord, ActivityRequest, ActivityService } from './shared';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { FilterConfig, FilterOption } from '../components/filter/filter.model.types';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
    public activityTitle = 'Activity';
    public activityRecords: ActivityRecord[] = [];
    public userSearchValue: string;
    public pageSize: number = 0;
    public totalRecords: number = 0;
    public fetching = true;
    public activityModal: MatDialogRef<ActivityModalComponent>;

    public userTypeFilterConfig: FilterConfig = {
        label: 'User Type',
        filterOptions: [
            { value: 'all', viewValue: 'All Users' },
            { value: 'internal', viewValue: 'Internal Only' },
            { value: 'external', viewValue: 'External Only' },
        ],
    };

    public featureFilterConfig: FilterConfig = {
        label: 'Feature / App',
        filterOptions: [
            { value: 'all', viewValue: 'All Features / Apps' },
            { value: 'cached-bank-codes', viewValue: 'Cached Bank Codes' },
            { value: 'brokerage-accounts', viewValue: 'Common Brokerage Accounts' },
            { value: 'vendors', viewValue: 'Pre-Approved Vendors' },
            { value: 'internal-admin', viewValue: 'Internal Admin' },
            { value: 'idd', viewValue: 'Deal Dashboard' },
            { value: 'sharp', viewValue: 'Shareholder Portal' },
        ],
    };

    public readonly range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });

    private modalConfigTemplate: MatDialogConfig = {
        panelClass: 'dialog__no-padding',
        disableClose: true,
        minWidth: '700px', // TODO Fix dimensions
        minHeight: '250px', // TODO Fix dimensions
    };

    private activityRequest: ActivityRequest = {
        userType: 'all',
        userSearch: '',
        feature: '',
        startDate: 0,
        endDate: 0,
        page: 0,
        size: 100,
        sortField: '',
        sortDir: '',
    };

    constructor(
        public matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private activityService: ActivityService,
    ) {}

    ngOnInit(): void {
        this.fetch(this.activityRequest);
    }

    public export() {
        const newNoteConfig: MatDialogConfig<ModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                title: 'Export Activities',
                action: 'export',
            },
        };

        this.openModal(newNoteConfig);
    }

    public filterTable(eventType: string, $event: FilterOption) {
        if (eventType === 'userType') {
            this.activityRequest.userType = $event?.value;
        } else if (eventType === 'feature') {
            this.activityRequest.feature = $event?.value;
        }

        this.fetch(this.activityRequest);
    }

    public performSearch(searchVal: string) {
        this.userSearchValue = searchVal;
        this.activityRequest.userSearch = searchVal;

        this.fetch(this.activityRequest);
    }

    public handleStartDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
        this.activityRequest.startDate = event?.value?.getTime();

        this.fetch(this.activityRequest);
    }

    public handleEndDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
        this.activityRequest.endDate = event?.value?.getTime();

        this.fetch(this.activityRequest);
    }

    public handlePageEvent(e: PageEvent) {
        this.activityRequest.size = e.pageSize;
        this.activityRequest.page = e.pageIndex;

        this.fetch(this.activityRequest);
    }

    public handleSortChange(s: Sort) {
        if (s.direction) {
            this.activityRequest.sortField = s.active;
            this.activityRequest.sortDir = s.direction;
            // Reset the page back to 0
            this.activityRequest.page = 0;

            this.fetch(this.activityRequest);
        } else {
            // Do not fetch if sorting has been turned off
            this.activityRequest.sortField = null;
            this.activityRequest.sortDir = null;
        }
    }

    private openModal(modalConfig: MatDialogConfig) {
        this.activityModal = this.matDialog.open(ActivityModalComponent, modalConfig);
        this.activityModal.afterClosed().subscribe((res: ModalResult) => {
            if (res?.exported) {
                this.snackBar.open('Activity data has been successfully exported', undefined, {
                    duration: 6000,
                });
            }
        });
    }

    private fetch(activityRequest: ActivityRequest) {
        this.fetching = true;

        this.activityService.get(activityRequest).subscribe({
            next: (res: any) => {
                this.fetching = false;
                const contentRange = res.headers.get('content-range'); // "1-3/3"
                this.totalRecords = +contentRange.substring(contentRange.indexOf('/') + 1);
                this.activityRecords = res.body;
            },
            error: (error) => this.handleError(error),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        this.fetching = false;
        this.activityRecords = [];
        console.error(error);
        this.snackBar.open('Server error', undefined, {
            duration: 10000,
        });
    }
}
