import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
    private readonly NUMBERS_ONLY_REGEXP = new RegExp(/[^0-9]*/g);

    constructor(
        private _el: ElementRef,
        private ngControl: NgControl,
    ) {}

    @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent): void {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(this.NUMBERS_ONLY_REGEXP, '');
        if (initalValue !== this._el.nativeElement.value) {
            this.ngControl.control?.setValue(this._el.nativeElement.value, { emitEvent: false });
            this.ngControl.control?.updateValueAndValidity(); // Trigger validation
            event.stopPropagation();
        }
    }
}
