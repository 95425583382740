import { Component } from '@angular/core';
import { AuthService, LocalStorageItems } from '../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-callback',
    styles: [],
    template: ``,
})
export class CallbackComponent {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
        this.authService.loginCallback().then(() => {
            const redirectUrl = decodeURIComponent(localStorage.getItem(LocalStorageItems.LOGIN_REDIRECT) || '/');

            // Parse the URL to extract the path and query parameters
            const url = new URL(redirectUrl);
            const path = url.pathname;
            const queryParams = {};
            url.searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            // Navigate using the path and query parameters
            this.router.navigate([path], { queryParams });
        });
    }
}
