<div class="bank-table-container">
    <div class="data-div">
        <table mat-table matSort (matSortChange)="handleSortChange($event)" [dataSource]="dataSource" class="table-div">
            <ng-container class="bfd-header-cell" matColumnDef="downloadFile">
                <th *matHeaderCellDef mat-header-cell>
                    <div class="download-checkbox-div">
                        <mat-checkbox (change)="setAll($event.checked)"
                                      [checked]="allChecked"
                                      [indeterminate]="someComplete"
                                      class="download-checkbox"
                                      id="download-header-checkbox"
                        ></mat-checkbox>
                    </div>
                </th>
                <td *matCellDef="let element; index as i;" mat-cell>
                    <div *ngIf="!element.file.isDir" class="download-checkbox-div">
                        <mat-checkbox (change)="selectedChange($event.checked, element)"
                                      [checked]="element?.selected"
                                      class="download-checkbox"
                                      id="download-checkbox-{{i}}"
                        ></mat-checkbox>
                    </div>
                </td>
            </ng-container>

            <ng-container class="bfd-header-cell" matColumnDef="bfdName">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="name"> Name</th>
                <td *matCellDef="let element"
                    mat-cell
                >
                    <div (click)="handleNameClick(element.file)"
                         [class.bank-table-name-dir]="element.file.isDir === true"
                         class="bank-table-name"
                    >
                        <span
                            class="material-symbols-outlined">{{ element.file.isDir === true ? 'folder_open' : 'description' }}</span>
                        {{ element.file.displayName }}
                    </div>
                </td>
            </ng-container>

            <ng-container class="bfd-header-cell" matColumnDef="bfdExt">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="ext"> Type</th>
                <td *matCellDef="let element"
                    [ignoreCase]="true"
                    [searchedWords]="searchText"
                    [text]="element.file.isDir === true ? 'Folder' : element.file.ext"
                    appHighlight
                    classToApply="selected"
                    mat-cell
                ></td>
            </ng-container>

            <ng-container class="bfd-header-cell" matColumnDef="bfdLastModified">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="lastModified"> Last Modified</th>
                <td *matCellDef="let element"
                    mat-cell
                >{{ getLastModifiedUTC(element.file.lastModified) }}
                </td>
            </ng-container>

            <ng-container class="bfd-header-cell" matColumnDef="bfdSize">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="size"> Size</th>
                <td *matCellDef="let element"
                    mat-cell
                >{{ element.file.size }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !fetching"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr *matNoDataRow class="mat-row">
                <td class="mat-cell no-data" colspan="5"><span *ngIf="!fetching">No data</span></td>
            </tr>
        </table>
    </div>
</div>
