import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, LocalStorageItems } from '../../core/services/auth.service';
import { User, UserService } from '../../core/services/user.service';
import { NavigationHelperService } from '../../core/services/navigation-helper.service';
import { UtilsService } from '../../core/services/utils.service';
import { UserRolesModalComponent } from '../../core/components/user-roles-modal/user-roles-modal.component';
import { UserRolesModalConfigData } from '../../core/components/shared/armory-header.types';

@Component({
    selector: 'app-armory-header',
    templateUrl: './armory-header.component.html',
    styleUrls: ['./armory-header.component.scss'],
})
export class ArmoryHeaderComponent {
    private readonly modalConfigTemplate: MatDialogConfig = {
        disableClose: false,
        width: '400px',
        maxHeight: '424px',
        autoFocus: 'first-header',
    };

    constructor(
        private matDialog: MatDialog,
        private authService: AuthService,
        private userService: UserService,
        private navigationHelperService: NavigationHelperService,
        private router: Router,
        private utilsService: UtilsService,
    ) {}

    public async logout() {
        // Save the fact that the user physically clicked "Log Out" to prevent
        // them from being automatically logging back in.
        localStorage.setItem(LocalStorageItems.LOGOUT_CLICKED, 'true');
        await this.authService.logout();
    }

    public get isDoneLoading$() {
        return this.authService.isAuthDoneLoading$;
    }

    public get isAuthenticated$() {
        return this.userService.isAuthenticated$;
    }

    public get user(): User {
        return this.userService.user;
    }

    public get userInitials() {
        return this.utilsService.getNameInitials(this.user?.name);
    }

    public goHome() {
        this.router.navigate(['']);
    }

    public login() {
        this.authService.login();
    }

    public toggleSidebar() {
        this.navigationHelperService.toggleSidebar();
    }

    public openAdminRolesModal() {
        const modalConfig: MatDialogConfig<UserRolesModalConfigData> = {
            ...this.modalConfigTemplate,
            data: {
                roles: this.utilsService.filterToPublicRoles(this.userService.user.roles),
            },
        };

        this.matDialog.open(UserRolesModalComponent, modalConfig);
    }
}
