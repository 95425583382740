import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { LoginComponent } from './components/login/login.component';
import { IdentityVerificationModule } from './identity-verification/identity-verification.module';
import { CoreModule } from './core/core.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { HomeComponent } from './components/home/home.component';
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { ArmoryHeaderComponent } from './components/armory-header/armory-header.component';
import { ArmorySidebarComponent } from './components/armory-sidebar/armory-sidebar.component';
import { CallbackComponent } from './components/callback/callback.component';
import { BrokerageAccountsModule } from './brokerage-accounts/brokerage-accounts.module';
import { VendorPaymentsModule } from './vendor-payments/vendor-payments.module';
import { CachedBankCodesModule } from './cached-bank-codes/cached-bank-codes.module';
import { InternalAdminModule } from './internal-admin/internal-admin.module';
import { DsComponentsShowcaseModule } from './ds-components-showcase/ds-components-showcase.module';
import { DsComponentsModule } from './ds-components/ds-components.module';
import { SharedModule } from './components/shared.module';
import { BankFileExplorerModule } from './bank-file-explorer/bank-file-explorer.module';
import { ActivityModule } from './activity/activity.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    declarations: [
        AppComponent,
        ArmoryHeaderComponent,
        ArmorySidebarComponent,
        CallbackComponent,
        HomeComponent,
        LoginComponent,
    ],
    imports: [
        BrokerageAccountsModule,
        CachedBankCodesModule,
        VendorPaymentsModule,
        InternalAdminModule,
        DsComponentsShowcaseModule,
        BankFileExplorerModule,
        BrowserModule,
        IdentityVerificationModule,
        ActivityModule,
        AppRoutingModule, // New applications must be imported before AppRoutingModule or users cannot navigate to them!
        DsComponentsModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        FormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatMenuModule,
        TableModule,
        SharedModule,
    ],
    providers: [MatSnackBar, Overlay, MessageService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export * from './ds-components/ds-components.module';
export { DIRECTIVES } from './ds-components/angular-component-library';
export * from './ds-components/angular-component-library/components';
