<mat-card appearance="outlined" class="mat-card">
    <div class="title-bar" id="title-bar">
        <mat-card-header>
            <mat-card-title>
                {{ modalData.title }}
            </mat-card-title>
        </mat-card-header>
        <span (click)="closeModal()"
              class="material-symbols-outlined x-dismiss"
              data-testid="x-dismiss"
              id="x-dismiss">
            close
        </span>
    </div>
    <div class="card__container">
        <ng-container
            *ngTemplateOutlet="viewText"
        ></ng-container>
    </div>
</mat-card>

<ng-template #viewText>
    <mat-card-content>
        <div class="file-details">
            <div class="subtitle">{{ modalData.selected.displayName }}</div>
            <div *ngIf="modalData.contents" class="file-contents-div">
                <pre class="file-contents-pre">{{ modalData.contents }}</pre>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            (click)="closeModal()"
            class="modal-button"
            data-testid="btnCancel"
            mat-flat-button
            matTooltipPosition="before"
        >
            Cancel
        </button>
    </mat-card-actions>
</ng-template>
