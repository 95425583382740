import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppRegistry, IAuthApps } from './auth.service';

export type User = {
    name: string;
    email: string;
    roles: string[];
    sub: string; // The OneLogin ID for the user that started the session
};

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public allowedApps$: BehaviorSubject<IAuthApps> = new BehaviorSubject({});
    private currentUser$: BehaviorSubject<User> = new BehaviorSubject({ name: '', email: '', roles: [], sub: '' });

    constructor() {}

    public set user(newUser: User) {
        this.currentUser$.next(newUser);
        this.setAllowedAppsFromUserRoles(this.currentUser$.getValue().roles);
    }

    public get user(): User {
        return this.currentUser$.getValue();
    }

    private setAllowedAppsFromUserRoles(roles: string[]) {
        const allowedApps: IAuthApps = {};
        const apps = Object.keys(AppRegistry);

        for (let role of roles) {
            for (let app of apps) {
                if (
                    AppRegistry[app].roles.find((appRole: string) => {
                        return appRole.toLowerCase() === role.toLowerCase();
                    }) !== undefined
                ) {
                    allowedApps[app] = AppRegistry[app];
                    // break;
                }
            }
        }
        this.allowedApps$.next(allowedApps);
    }
}
