<div class="main-div" id="main-div">
    <table (matSortChange)="handleSortChange($event)" [dataSource]="dataSource" class="table-div" mat-table matSort>
        <ng-container matColumnDef="deal">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="deal">Deal</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span class="actionable-item"
                      (click)="handleDealClicked(element.dealId)"
                      [ignoreCase]="true"
                      [searchedWords]="searchText"
                      [text]="element.deal"
                      appHighlight
                      classToApply="selected"
                >{{ element.deal }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="user">Users</th>
            <td mat-cell
                *matCellDef="let element"
            >
                <span class="actionable-item"
                      (click)="handleUserClicked(element.userId)"
                      [ignoreCase]="true"
                      [searchedWords]="searchText"
                      [text]="element.user"
                      appHighlight
                      classToApply="selected"
                >{{ element.user }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="permission">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="permission">Role</th>
            <td *matCellDef="let element"
                mat-cell
            >{{ getPermission(element.permission) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="shareholder">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="shareholder">Shareholder</th>
            <td *matCellDef="let element"
                mat-cell
            >
                <span class="actionable-item"
                      (click)="handleShareholderClicked(element.shareholderId)"
                >{{ element.shareholder }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="emailStatus">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="emailStatus">Email Status</th>
            <td *matCellDef="let element"
                mat-cell
            >{{ element.emailStatus }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let element; index as idx;"
            >
                <div class="action-cell"
                     [class.action-cell-clicked]="idx === selectedIndex"
                     [matMenuTriggerFor]="menu"
                     aria-label="Deal action menu"
                     (click)="openMenu($event, idx)"
                     (menuClosed)="handleMenuClosedEvent($event, idx)"
                >
                    <div class="action-div">
                        <span class="material-symbols-outlined"
                        >more_horiz</span>
                    </div>
                </div>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                    <div mat-menu-item class="menu-option" (click)="handleEditUserClick(element.userId)">Edit User</div>
                    <div *ngIf="showRevokeAccess"
                         mat-menu-item
                         class="menu-option menu-option-red"
                         (click)="handleRevokeAccessClick(element)">Revoke Access</div>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="intadm-table-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
            <td class="mat-cell no-data" colspan="10"><span *ngIf="!fetching">No data</span></td>
        </tr>
    </table>
</div>
<div class="paginator-div">
    <mat-paginator (page)="handlePageEvent($event)"
                   [length]="totalRecords"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   [pageSize]="pageSize"
                   aria-label="Select page"
                   disabled=false
                   hidePageSize=false
                   showFirstLastButtons=true>
    </mat-paginator>
</div>
