import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { DealRecord } from '../shared';
import { ConfigurationService } from '../../core/services/configuration.service';
import { DealAvailableColumns } from './deal-table.column.types';
import { Router } from '@angular/router';

@Component({
    selector: 'app-deal-table',
    templateUrl: './deal-table.component.html',
    styleUrls: ['./deal-table.component.scss'],
})
export class DealTableComponent implements AfterViewInit, OnChanges {
    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.hideMenu();
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // If this element does not contain the target element, then a click
        // happened outside the panel and should be closed. But because this is
        // listening to all document clicks, including the click to open the
        // slider, the click count needs to be reset after (re)opening. This is
        // to effectively ignore initial click to open the container.
        if (!this.eRef.nativeElement.contains(event.target) && this.clickCount > 0 && this.selectedIndex >= 0) {
            this.hideMenu();
        }

        this.clickCount++;
    }

    @Input() dealRecords: DealRecord[];
    @Input() searchText: string;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() totalRecords: number = 0;
    @Input() showCreateUser: boolean;
    @Input() showRevokeAccess: boolean;
    @Input() fetching: boolean;
    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() sortEvent = new EventEmitter<Sort>();
    @Output() dealActivityNav = new EventEmitter<number>();
    @Output() dealDashboardNav = new EventEmitter<number>();
    @Output() revokeAccessEvent = new EventEmitter<DealRecord>();

    @ViewChild(MatSort) sort: MatSort;

    public dataSource: MatTableDataSource<DealRecord>;
    public get displayedColumns(): string[] {
        return this._displayedColumns;
    }
    @Input() set displayedColumns(value: string[]) {
        if (value && value.length > 0) {
            this._displayedColumns = value;
        } else {
            this._displayedColumns = this.defaultColumns;
        }
    }
    // public displayedColumns = ['nsId', 'name', 'projectName', 'seller', 'buyer', 'closingDt', 'actions'];
    private _displayedColumns: string[];
    public defaultColumns: string[] = [
        DealAvailableColumns.NsId,
        DealAvailableColumns.Name,
        DealAvailableColumns.ProjectName,
        DealAvailableColumns.Seller,
        DealAvailableColumns.Buyer,
        DealAvailableColumns.ClosingDate,
        DealAvailableColumns.Actions,
    ];
    public iconWasClicked = false;
    public selectedIndex = null;
    private clickCount = 0;

    constructor(
        private eRef: ElementRef,
        private configurationService: ConfigurationService,
        private router: Router,
    ) {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.dealRecords);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(_: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.dealRecords);
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent.emit(e);
    }

    handleSortChange(s: Sort) {
        this.sortEvent.emit(s);
    }

    handleDealSelected(d: DealRecord) {
        this.router.navigate(['/internal-admin/deals'], {
            queryParams: { dealId: d.id },
        });
    }

    handleRevokeAccessClick(element) {
        this.hideMenu();
        this.revokeAccessEvent.emit(element);
    }

    openMenu($event: MouseEvent, index: number) {
        this.iconWasClicked = true;
        this.selectedIndex = index;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    public handleMenuClosedEvent($event, index: number) {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        $event?.stopPropagation();
        $event?.preventDefault();
    }

    hideMenu() {
        this.iconWasClicked = false;
        this.selectedIndex = -1;
        this.clickCount = 0;
    }

    navToDealActivity(idx: number) {
        this.hideMenu();

        this.dealActivityNav.emit(idx);
    }

    navToDealDashboard(idx: number) {
        this.hideMenu();

        this.dealDashboardNav.emit(idx);
    }

    public openInNewTab($event, url: string) {
        // This is to prevent the click event from propagating up.
        $event?.stopPropagation();
        $event?.preventDefault();
        window.open(url, '_blank').focus();
    }

    public getDealNsUrl(nsId: number): string {
        const nsCustomerUrl = this.configurationService.envConfig.getValue().nsCustomerUrl;

        return nsCustomerUrl && nsId ? nsCustomerUrl.replace(/INSERTIDHERE/, nsId.toString()) : '';
    }
}
