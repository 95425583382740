import { Injectable } from '@angular/core';
import { InvalidGiactEntityErrors, states } from '../../identity-verification/types/giact.types';
import { IParsedCsvObject } from '../utils.types';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private publicRoles = [
        'Static Group - Armory - Auditor',
        'Static Group - Brokerage Accounts',
        'Static Group - Brokerage Accounts - Admin',
        'Static Group - Cache',
        'Static Group - Cache - Admin',
        'Static Group - Eng Bank File Viewer - Standard',
        'Static Group - Eng Internal Admin',
        'Static Group - Eng Internal Admin - Standard',
        'Static Group - Vendor Payments',
        'Static Group - Vendor Payments - Approve',
        'Static Group - Vendor Payments - Edit',
    ];

    constructor() {}

    public checkValidABA(abaNumber: string): boolean {
        // ABA numbers may only be 9 digits exactly
        if (abaNumber.length !== 9) {
            return false;
        }
        // ABA numbers may only contain numeric digits
        if (abaNumber.search(new RegExp(/\D/g)) !== -1) {
            return false;
        }
        return true;
    }

    public checkValidBIC(bicNumber: string): boolean {
        // BIC numbers may only be 8 OR 11 digits exactly
        if (bicNumber.length !== 8 && bicNumber.length !== 11) {
            return false;
        }
        // BIC numbers may only contain alphanumeric digits
        if (bicNumber.search(new RegExp(/[^a-zA-Z0-9]/g)) !== -1) {
            return false;
        }
        return true;
    }

    public validateCountry(countryCode): string {
        if (countryCode) {
            const isValid = countryCode.toLowerCase() === 'us';
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_COUNTRY} Country: ${countryCode}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_COUNTRY} Country: ${countryCode}`;
    }

    public validateBankAccountNumber(accountNumber: string): string {
        if (accountNumber) {
            const accountNumberRegex = /^(\d|[a-zA-Z]){4,17}$/;
            const isValid = accountNumberRegex.test(accountNumber);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_ACCOUNT_NUMBER} AccountNumber: ${accountNumber}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_ACCOUNT_NUMBER} AccountNumber: ${accountNumber}`;
    }

    public validateBankRoutingNumber(routingNumber: string): string {
        if (routingNumber) {
            const routingNumberRegex = /^\d{9}$/;
            const isValid = routingNumberRegex.test(routingNumber);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_ROUTING_NUMBER} RoutingNumber: ${routingNumber}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_ROUTING_NUMBER} RoutingNumber: ${routingNumber}`;
    }

    public validateEmailAddress(emailAddress: string): string {
        if (emailAddress) {
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const isValid = emailRegex.test(emailAddress);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_EMAIL_ADDRESS} EmailAddress: ${emailAddress}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_EMAIL_ADDRESS} EmailAddress: ${emailAddress}`;
    }

    public validateIpAddress(ipAddress: string): string {
        if (ipAddress) {
            // eslint-disable-next-line max-len
            const ipRegex =
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            const isValid = ipRegex.test(ipAddress);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_IP_ADDRESS} CurrentIpAddress: ${ipAddress}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_IP_ADDRESS} CurrentIpAddress: ${ipAddress}`;
    }

    public validateState(stateCode: string): string {
        if (stateCode) {
            const isValid = states.indexOf(stateCode.toUpperCase()) !== -1;
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_STATE} State: ${stateCode}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_STATE} State: ${stateCode}`;
    }

    public validateTaxId(taxId: string): string {
        if (taxId) {
            const taxIdRegex = /^\d{4}(?:\d{5})?$/;
            const isValid = taxIdRegex.test(taxId);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_TAX_ID} TaxId: ${taxId}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_TAX_ID} TaxId: ${taxId}`;
    }

    public validateZipCode(zipCode: string): string {
        if (zipCode) {
            const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
            const isValid = zipRegex.test(zipCode);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_ZIP_CODE} ZipCode: ${zipCode}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_ZIP_CODE} ZipCode: ${zipCode}`;
    }

    public validatePhoneNumber(phoneNumber: string): string {
        if (phoneNumber) {
            const phoneRegex = /^\d{10}$/;
            const isValid = phoneRegex.test(phoneNumber);
            if (!isValid) {
                return `${InvalidGiactEntityErrors.INVALID_PHONE_NUMBER} PhoneNumber: ${phoneNumber}`;
            }
            return;
        }
        return `${InvalidGiactEntityErrors.INVALID_PHONE_NUMBER} PhoneNumber: ${phoneNumber}`;
    }

    public validateAddress(address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        zipCode: string;
        country: string;
    }): string[] {
        const addressValidations: string[] = [];
        if (address.addressLine1) {
            if (!address.city) {
                addressValidations.push(`${InvalidGiactEntityErrors.INVALID_CITY} City: ${address.city}`);
            }
            const stateValidation = this.validateState(address.state);
            const zipCodeValidation = this.validateZipCode(address.zipCode);
            const countryValidation = this.validateCountry(address.country);
            if (stateValidation) {
                addressValidations.push(stateValidation);
            }
            if (zipCodeValidation) {
                addressValidations.push(zipCodeValidation);
            }
            if (countryValidation) {
                addressValidations.push(countryValidation);
            }
        } else {
            // TODO: Better error message handling.... shouldn't have to manage error messages in the function
            if (address.addressLine2) {
                addressValidations.push(
                    `${InvalidGiactEntityErrors.INVALID_ADDRESS_LINE_2} AddressLine1: ${address.addressLine1}`,
                );
            }
            if (address.city) {
                addressValidations.push(
                    `AddressLine1 must be filled in order to provide a City. AddressLine1: ${address.addressLine1}`,
                );
            }
            if (address.state) {
                addressValidations.push(
                    `AddressLine1 must be filled in order to provide a State. AddressLine1: ${address.addressLine1}`,
                );
            }
            if (address.zipCode) {
                addressValidations.push(
                    `AddressLine1 must be filled in order to provide a ZipCode. AddressLine1: ${address.addressLine1}`,
                );
            }
            if (address.country) {
                addressValidations.push(
                    `AddressLine1 must be filled in order to provide a Country. AddressLine1: ${address.addressLine1}`,
                );
            }
        }
        return addressValidations;
    }

    public convertCsvRowToArray(text: string): null | string[] {
        const validRegex =
            /^\s*(?:"[^"]*(?:""[\S\s][^"]*)*(?:"")*"|[^,"\s\\]*(?:\s+[^,"\s\\]+)*)\s*(?:,\s*(?:"[^"]*(?:""[\S\s][^"]*)*(?:"")*"|[^,"\s\\]*(?:\s+[^,"\s\\]+)*)\s*)*$/;
        const valueRegex =
            /(?!\s*$)\s*(?:"([^"]*(?:""[\S\s][^"\\]*)*(?:"")*)"|([^,"\s\\]*(?:\s+[^,"\s\\]+)*))\s*(?:,|$)/g;
        // Return NULL if input string is not well formed CSV string.
        if (!validRegex.test(text)) {
            return null;
        }
        // Initialize array to receive values.
        const a = [];
        /**
         * _: the substring that was matched by the regex
         * group1: capturing group 1, string encapsulated in double quotes "
         * group2: capturing group 2, string that is not encapsulated in any quotes
         */
        text.replace(
            valueRegex, // "Walk" the string using replace with callback.
            (_, group1, group2) => {
                if (group1 !== undefined) {
                    // Check for values inside of double quotes "
                    a.push(group1.replace(/""/g, '"')); // Replace all instances of doubled double quotes with single double quote
                } else if (group2 !== undefined) {
                    a.push(group2);
                }
                return ''; // Return empty string.
            },
        );
        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) {
            a.push('');
        }
        return a;
    }

    public checkAllowedKeys(keySource, allowedKeys: string[]): string {
        const mismatchedKeys: string[] = [];
        Object.keys(keySource).forEach((key: string) => {
            if (allowedKeys.indexOf(key) === -1) {
                mismatchedKeys.push(key);
            }
        });
        return mismatchedKeys.length > 0
            ? `Unexpected key(s) encountered. Allowed keys: ${allowedKeys.join(
                  ', ',
              )}. Unexpected key(s): ${mismatchedKeys.join(', ')}`
            : undefined;
    }

    public parseCsvToObject<T>(text: string): IParsedCsvObject<T> {
        let lines = text.split(/\r\n|\r|\n/g);
        lines = lines[lines.length - 1] === '' ? lines.slice(0, lines.length - 1) : lines;
        // If we receive only 1 row or an empty string then we can't parse anything so we just return
        if (lines.length < 2) {
            return { validRows: [] };
        }
        const headers = this.convertCsvRowToArray(lines[0]);
        if (headers === null) {
            return { validRows: [], invalidRows: [{ lineNumber: 0, record: 'nullHeader' }] };
        }
        const rows = lines.slice(1);
        const validRows: { lineNumber: number; record: T }[] = [];
        const invalidRows: { lineNumber: number; record: any }[] = [];
        const duplicateRows: { line: number; matchedLine: number }[] = [];
        const seenRows: { [line: number]: string } = {};
        rows.forEach((record: string, index: number) => {
            const recordArray = this.convertCsvRowToArray(record);
            if (recordArray === null) {
                invalidRows.push({
                    lineNumber: index + 1,
                    record: null,
                });
                // Check for invalid rows which have unequal headers and values
            } else if (recordArray.length !== headers.length) {
                const indexDiff = recordArray.length - headers.length;
                const headersForUnknownValues: string[] = [];
                const valuesForUnknownHeaders: string[] = [];
                if (indexDiff > 0) {
                    for (let i = 0; i < indexDiff; i++) {
                        headersForUnknownValues.push(`unknownHeader${i + 1}`);
                    }
                } else {
                    for (let i = 0; i < Math.abs(indexDiff); i++) {
                        valuesForUnknownHeaders.push(`unknownValue${i + 1}`);
                    }
                }
                invalidRows.push({
                    lineNumber: index + 1,
                    record: this.createObjectFromCsvHeadersAndRows<T>(
                        [...headers, ...headersForUnknownValues],
                        [...recordArray, ...valuesForUnknownHeaders],
                    ),
                });
            } else {
                // Check if duplicate
                let matchedLine: number = -1;
                const isDuplicate = Object.keys(seenRows).some((line) => {
                    if (seenRows[line] === record) {
                        matchedLine = +line;
                        return true;
                    }
                    return false;
                });
                if (isDuplicate) {
                    duplicateRows.push({ line: index + 1, matchedLine: matchedLine + 1 });
                } else {
                    seenRows[index] = record;
                    validRows.push({
                        lineNumber: index + 1,
                        record: this.createObjectFromCsvHeadersAndRows<T>([...headers], [...recordArray]),
                    });
                }
            }
        });
        const returnValue: IParsedCsvObject<T> = { validRows };
        if (invalidRows.length > 0) {
            returnValue.invalidRows = invalidRows;
        }
        if (duplicateRows.length > 0) {
            returnValue.duplicateRows = duplicateRows;
        }
        return returnValue;
    }

    public createObjectFromCsvHeadersAndRows<T>(csvHeaders: string[], csvRows: string[]): T {
        const mappedObject: T = {} as T;
        if (csvHeaders.length === csvRows.length) {
            csvHeaders.forEach((header: string, headerIndex: number) => {
                mappedObject[header] = csvRows[headerIndex];
            });
        } else {
            throw new Error('unable to map headers and rows to object!');
        }
        return mappedObject;
    }

    public removeDisallowedCharacters(inputString: string): string {
        if (typeof inputString !== 'string') {
            throw new Error('Input must be of type string');
        }
        const disallowedCharsRegex = /[^a-zA-Z0-9/\-?:().,'+ ]*/g;
        return inputString.replace(disallowedCharsRegex, '');
    }

    /**
     * A VERY limited date formatter. Only does 24 hour time, no millseconds, and no timezone.
     * The goal was to have something that is easily replaceable with a more robust date/time formatter
     * should that need ever arise.
     * @param desiredFormat: string
     *  yyyy/yy for year (2014/14)
     *  MM/M for month with/without zero padding
     *  dd/d for day with/without zero padding
     *  HH/H for 24-hour-clock hours with/without zero padding
     *  mm/m for minutes with/without zero padding
     *  ss/s for seconds with/without zero padding
     * @param date: Date the date you want formatted
     */
    public formatDateAsStringPattern(desiredFormat: string, date: Date): string {
        let formattedDate = desiredFormat;
        // year
        formattedDate = formattedDate.replace('yyyy', date.getFullYear().toString());
        formattedDate = formattedDate.replace('yy', date.getFullYear().toString().substr(-2));
        // month
        formattedDate = formattedDate.replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'));
        formattedDate = formattedDate.replace('M', (date.getMonth() + 1).toString());
        // day
        formattedDate = formattedDate.replace('dd', date.getDate().toString().padStart(2, '0'));
        formattedDate = formattedDate.replace('d', date.getDate().toString());
        // hours - only works with 24 hours, no AM or PM!
        formattedDate = formattedDate.replace('HH', date.getHours().toString().padStart(2, '0'));
        formattedDate = formattedDate.replace('H', date.getHours().toString());
        // minutes
        formattedDate = formattedDate.replace('mm', date.getMinutes().toString().padStart(2, '0'));
        formattedDate = formattedDate.replace('m', date.getMinutes().toString());
        // seconds
        formattedDate = formattedDate.replace('ss', date.getSeconds().toString().padStart(2, '0'));
        formattedDate = formattedDate.replace('s', date.getSeconds().toString());

        return formattedDate;
    }

    public getArrayIntersection(arrA: (string | number)[], arrB: (string | number)[]): Set<string | number> {
        return new Set(
            arrA.filter((x) => {
                return arrB.includes(x);
            }),
        );
    }

    // Returns the first and last initial of a name
    public getNameInitials(name: string): string {
        const names = name?.trim().split(' ') ?? [];
        if (names.length === 0 || names[0] === '') return '';

        let initials = names[0][0];
        if (names.length > 1) {
            initials += names[names.length - 1][0];
        }
        return initials.toUpperCase();
    }

    public filterToPublicRoles(roles: string[]): string[] {
        return roles.filter((role) => this.publicRoles.includes(role));
    }
}
