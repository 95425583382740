import { Injectable } from '@angular/core';
import { UserStatus } from './user.types';

@Injectable({
    providedIn: 'root',
})
export class UserHelperService {
    constructor() {}

    public canEditUser(status: string): boolean {
        return !!status && (status.toLowerCase() === UserStatus.Invited || status.toLowerCase() === UserStatus.Active);
    }

    public canViewUserActivity(status: string): boolean {
        return true;
    }

    public canResendInvitation(status: string): boolean {
        return !!status && status.toLowerCase() === UserStatus.Invited;
    }

    public canSendPwReset(status: string): boolean {
        return !!status && (status.toLowerCase() === UserStatus.Active || status.toLowerCase() === UserStatus.Blocked);
    }

    public canBlock(status: string): boolean {
        return !!status && status.toLowerCase() !== UserStatus.Invited && status.toLowerCase() !== UserStatus.Blocked;
    }

    public canUnblock(status: string): boolean {
        return !!status && status.toLowerCase() === UserStatus.Blocked;
    }

    public canDeactivate(status: string): boolean {
        return !!status && status.toLowerCase() !== UserStatus.Deactivated;
    }
}
