import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalConfigData } from '../shared';

@Component({
    selector: 'app-bfp-modal',
    templateUrl: './bfp-modal.component.html',
    styleUrls: ['./bfp-modal.component.scss'],
})
export class BfpModalComponent implements OnInit {
    protected readonly JSON = JSON;

    constructor(
        @Optional() public dialogRef: MatDialogRef<BfpModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: ModalConfigData,
    ) {}

    ngOnInit(): void {
        // Close the dialog if the user presses the Escape key.
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event?.key === 'Escape') {
                this.closeModal();
            }
        });

        // Close the dialog if the user clicks outside the dialog.
        this.dialogRef.backdropClick().subscribe((e) => {
            if (e != null) {
                // null is getting passed during unit tests; this avoids testing problems.
                this.closeModal();
            }
        });
    }

    public closeModal() {
        this.dialogRef.close();
    }
}
