import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    @Input() initialValue: string = '';
    @Input() placeholder: string = '';
    @Output() newSearchEvent = new EventEmitter<string>();

    searchControl: FormControl<string> = new FormControl<string>('');

    constructor() {}

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(500), // Fire the callback method only if not fired in a specific interval.
                distinctUntilChanged(), // Execute only if a different value is emitted.
            )
            .subscribe((filterValue) => {
                this.newSearchEvent.emit(filterValue);
            });
    }

    public onSrsaTextFieldChange($event: CustomEvent<any>) {
        this.searchControl.setValue($event?.detail?.value);
    }
}
