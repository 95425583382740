import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRolesModalConfigData } from '../shared/armory-header.types';

@Component({
    selector: 'app-user-roles-modal',
    templateUrl: './user-roles-modal.component.html',
    styleUrls: ['./user-roles-modal.component.scss'],
})
export class UserRolesModalComponent {
    constructor(
        @Optional() public dialogRef: MatDialogRef<UserRolesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: UserRolesModalConfigData,
    ) {}

    public closeModal() {
        this.dialogRef.close();
    }
}
