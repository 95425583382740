import { HttpParams } from '@angular/common/http';
import { ActivityRequestHelper } from './activity.types';

export enum GetType {
    'deals' = 'd',
    'users' = 'u',
    'shareholders' = 's',
    'activity' = 'a',
    'roles' = 'r',
    'shareholderUserAccess' = 'sua',
    'dealUserAccess' = 'dua',
}

export class AdminRequestHelper {
    public id: number = 0; // Id of the type retrieved: deal id, user id, etc.
    public uid: number = 0; // User id
    public sid: number = 0; // Shareholder id
    public did: number = 0; // Deal id
    public iuOnly: boolean = false; // All activity by internal users only

    public page: number = 0;
    public size: number = 100;

    public srchVal: string = null;
    public srchFields: string[] = null;

    public sortField: string;
    public sortDir: string;

    public getMfa: boolean = false;

    public activityRequestHelper: ActivityRequestHelper;

    private type: GetType; // t=d: (t)ype = (d)eal

    public constructor(t: GetType) {
        this.type = t;
    }

    public clear() {
        this.id = 0;
        this.uid = 0;
        this.sid = 0;
        this.did = 0;
        this.iuOnly = false;
        this.page = 0;
        this.size = 100;
        this.srchVal = null;
        this.srchFields = null;
        this.sortField = null;
        this.sortDir = null;
        this.getMfa = false;
        this.activityRequestHelper = null;
    }

    public toHttpParams() {
        let params = new HttpParams().set('t', this.type).set('page', this.page).set('size', this.size);

        if (this.id) {
            params = params.set('id', this.id);
        }

        if (this.uid) {
            params = params.set('uid', this.uid);
        }

        if (this.sid) {
            params = params.set('sid', this.sid);
        }

        if (this.did) {
            params = params.set('did', this.did);
        }

        if (this.iuOnly) {
            params = params.set('iu', 'all');
        }

        if (this.srchVal && this.srchFields && Array.isArray(this.srchFields) && this.srchFields.length > 0) {
            params = params.set('sv', this.srchVal).set('sf', JSON.stringify(this.srchFields));
        }

        if (this.sortField) {
            params = params.set('sort', this.sortField + (this.sortDir && this.sortDir === 'asc' ? ':asc' : ':desc'));
        }

        if (this.getMfa) {
            params = params.set('getMfa', 'true');
        }

        if (this.activityRequestHelper) {
            if (this.activityRequestHelper.srchval) {
                params = params.set('actsv', this.activityRequestHelper.srchval);
            }

            if (this.activityRequestHelper.activity) {
                params = params.set('actactv', this.activityRequestHelper.activity);
            }

            if (this.activityRequestHelper.action) {
                params = params.set('actactn', this.activityRequestHelper.action);
            }

            if (this.activityRequestHelper.startdt) {
                params = params.set('actsd', this.activityRequestHelper.startdt);
            }

            if (this.activityRequestHelper.enddt) {
                params = params.set('acted', this.activityRequestHelper.enddt);
            }

            if (this.activityRequestHelper.excludename) {
                params = params.set(
                    'actem',
                    this.activityRequestHelper.excludename +
                        ':' +
                        (this.activityRequestHelper?.excludeid ? this.activityRequestHelper.excludeid : ''),
                );
            }

            if (this.activityRequestHelper.bynameunique) {
                params = params.set('actbnu', 'true');
            }
        }

        return params;
    }
}
