import { ConfigurationService } from './services/configuration.service';
import { IConfiguration } from './configuration.model';
import { AuthService, LocalStorageItems } from './services/auth.service';

const CONFIG_JSON_PATH: string = '/assets/config/config.json';

export function initConfigAndAuthServer(
    configService: ConfigurationService,
    authService: AuthService,
): () => Promise<void> {
    return () => {
        return new Promise(async (resolve, reject) => {
            const rawConfig = await fetch(window.location.origin + CONFIG_JSON_PATH + `?t=${new Date().getTime()}`);
            const configJSON: IConfiguration = await rawConfig.json();
            configService.config = configJSON;

            if (
                configService.config.oidcConfig &&
                configService.config.oidcConfig.issuer &&
                configService.config.oidcConfig.issuer !== '' &&
                configService.config.oidcConfig.clientId &&
                configService.config.oidcConfig.clientId !== ''
            ) {
                await authService.establishAuthorizationServer(configService.config);

                const isAuthenticated = authService.initializeSession();

                const didClickLogout = localStorage.getItem(LocalStorageItems.LOGOUT_CLICKED);
                const loginAlreadyStarted = localStorage.getItem(LocalStorageItems.LOGIN_STARTED);

                if (!isAuthenticated && (!didClickLogout || didClickLogout !== 'true') && !loginAlreadyStarted) {
                    localStorage.setItem(LocalStorageItems.LOGIN_STARTED, 'true');
                    localStorage.setItem(LocalStorageItems.LOGIN_REDIRECT, encodeURIComponent(window.location.href));
                    authService.login();
                }
                resolve();
            } else {
                reject(
                    new Error('missing necessary OIDC information from config, unable to contact identity provider'),
                );
            }
        });
    };
}
