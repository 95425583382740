<mat-card class="mat-card">
    <div id="title-bar" class="title-bar">
        <mat-card-title>My Admin Roles</mat-card-title>
        <span id="x-dismiss"
              (click)="closeModal()"
              class="material-symbols-outlined x-dismiss"
              data-testid="x-dismiss">
            close
        </span>
    </div>
    <mat-card-content>
        <div *ngFor="let role of modalData.roles.sort()">
            {{role}}
        </div>
    </mat-card-content>
    <mat-card-actions align="end" class="card-actions">
        <button mat-stroked-button (click)="closeModal()">Close</button>
    </mat-card-actions>
</mat-card>
