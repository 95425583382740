<header class="admin__header">
    <mat-toolbar color="primary">
        <div class="left-action">
            <h1 class="admin-icon-header">
                <img [src]="'assets/SRSA Logo KO.png'" alt="SRSA logo"
                     (click)="goHome()"/>
<!--                <a class="home_link" href="/"> Admin </a>-->
            </h1>
            <span id="export-span"
                  class="material-symbols-outlined app-menu"
                  (click)="toggleSidebar()"
                  *ngIf="(isAuthenticated$ | async)"
            >menu</span>
        </div>
        <div class="right-action">
            <div
                [matMenuTriggerFor]="profileMenu"
                aria-label="Profile menu"
                class="profile-icon-button"
                *ngIf="(isDoneLoading$ | async) && (isAuthenticated$ | async)"
            >
                <app-profile-icon [initials]="userInitials"></app-profile-icon>
            </div>
            <button
                mat-flat-button
                color="primary"
                (click)="login()"
                class="button"
                *ngIf="(isDoneLoading$ | async) && !(isAuthenticated$ | async)"
            >
                Log In
            </button>
            <mat-menu class="profile-menu" #profileMenu="matMenu" xPosition="before" yPosition="below">
                <div class="user-info-section" (click)="$event.stopPropagation()">
                    <div class="profile-icon-container">
                        <app-profile-icon [initials]="userInitials" [textColor]="'#1A1A1A'"></app-profile-icon>
                    </div>
                    <div class="user-name-container">
                        <div class="user-name">{{user.name}}</div>
                        <div class="user-email">{{user.email}}</div>
                    </div>
                </div>
                <mat-divider class="confirm-divider"></mat-divider>
                <div mat-menu-item (click)="openAdminRolesModal()" class="menu-option open-admin-roles-modal-button">My Admin Roles</div>
                <div mat-menu-item (click)="logout()" class="menu-option log-out-button">Log Out</div>
            </mat-menu>
        </div>
    </mat-toolbar>
</header>
