<div id="search-div" class="search-div">
    <srsa-text-field
        ngDefaultControl
        id="searchInput"
        leading-icon="filter_list"
        type="text"
        class="search-input"
        [label]="placeholder"
        internal-class="medium-text-field"
        (srsaTextFieldChange)="onSrsaTextFieldChange($event)"
        [value]="initialValue"
    ></srsa-text-field>
</div>
