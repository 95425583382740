<mat-card class="mat-card">
  <div id="title-bar" class="title-bar">
      <mat-card-title>
          {{ modalData.title }}
      </mat-card-title>
      <span id="x-dismiss-modal"
            (click)="cancelModal($event)"
            class="material-symbols-outlined x-dismiss-modal"
            data-testid="x-dismiss-modal">
          close
      </span>
  </div>
  <div *ngIf="modalData.action == null" class="card__container">
      <ng-container
          *ngTemplateOutlet="projectSpecificCreateEditUser"
      ></ng-container>
  </div>
  <div *ngIf="modalData.action === UserActionEnum.resetMfa ||
              modalData.action === UserActionEnum.sendPwReset ||
              modalData.action === UserActionEnum.deactivate ||
              modalData.action === UserActionEnum.resendInv"
       class="card__container">
      <ng-container
          *ngTemplateOutlet="userAuth0Action"
      ></ng-container>
  </div>
  <div *ngIf="modalData.action === UserActionEnum.revokeAccessDD ||
              modalData.action === UserActionEnum.revokeAccessSH ||
              modalData.action === UserActionEnum.revokeAllAccess">
      <ng-container
          *ngTemplateOutlet="revokeAccess"
      ></ng-container>
  </div>
  <div *ngIf="modalData.action === UserActionEnum.block">
      <ng-container
          *ngTemplateOutlet="blockAuthentication"
      ></ng-container>
  </div>
  <div *ngIf="modalData.action === UserActionEnum.unblock">
      <ng-container
          *ngTemplateOutlet="unblockAuthentication"
      ></ng-container>
  </div>
</mat-card>

<ng-template #projectSpecificCreateEditUser>
  <mat-card-content *ngIf="!modalData.userName && !modalData.dealName">
    <div>You are about to cancel with <span class="bold-text">unsaved changes.</span></div>
  </mat-card-content>
  <mat-card-content *ngIf="modalData.userName && modalData.dealName">
    <div>This will revoke
        <span class="bold-text">{{modalData.userName}}</span> access from <span class="bold-text">{{modalData.dealName}}.</span></div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button data-testid="cancel-button" mat-flat-button (click)="cancelModal($event)">Cancel</button>
    <button data-testid="confirm-button"
            mat-flat-button
            (click)="confirmModal($event)"
            class="confirm-button">
        {{modalData.action === UserActionEnum.resetMfa ? 'Remove Method' : 'Confirm'}}
    </button>
  </mat-card-actions>
</ng-template>

<ng-template #userAuth0Action>
    <mat-card-content *ngIf="modalData.messageHTML">
        <div [innerHTML]="modalData.messageHTML"></div>
    </mat-card-content>
    <mat-card-content *ngIf="!modalData.messageHTML">
        <div class="alert-div">
            <mat-icon aria-hidden="false" aria-label="error outline icon">error_outline</mat-icon>
            <div class="text-grouping">
                <div class="primary">
                    Deactivation is a permanent action
                </div>
                <div class="secondary">
                    There is no reactivation available at this time.
                </div>
            </div>
        </div>
        <div class="checkbox-div">
            <mat-checkbox name="test" [disableRipple]="true" class="deactivate-checkbox" data-testid="deactivate-checkbox" (change)="checkClicked($event)"></mat-checkbox>
            <div class="checkbox-label">
                I understand this will delete the user from Auth0, and
                remove their user record in the Admin Tool.
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <div class="action-icon">
            <button class="secondary-button" data-testid="cancel-button-auth0" mat-flat-button (click)="cancelModal($event)">Cancel</button>
        </div>
            <button data-testid="confirm-button-auth0"
                  mat-flat-button
                  (click)="confirmModal($event)"
                  [disabled]="!deactivateCheckbox
                              && modalData.action === UserActionEnum.deactivate
                              && !modalData.deactivateFromUserTable"
                  [class.action-icon-primary]="modalData.action !== UserActionEnum.deactivate"
                  [class.confirm-button]="modalData.action === UserActionEnum.deactivate || modalData.action === UserActionEnum.resetMfa"
            >{{modalData.action === UserActionEnum.deactivate ? 'Deactivate User' : 'Confirm'}}</button>
    </mat-card-actions>
</ng-template>

<ng-template #revokeAccess>
    <mat-card-content>
        <div [innerHTML]="modalData.messageHTML"></div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button data-testid="cancel-button" mat-flat-button (click)="cancelModal($event)">Cancel</button>
        <button data-testid="confirm-button" mat-flat-button (click)="confirmModal($event)" class="confirm-button">
            {{modalData.action === UserActionEnum.revokeAccessDD || modalData.action === UserActionEnum.revokeAccessSH ? 'Revoke Access' : 'Revoke All Access'}}</button>
    </mat-card-actions>
</ng-template>

<ng-template #blockAuthentication>
    <mat-card-content>
        <div [innerHTML]="modalData.messageHTML"></div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button data-testid="cancel-button" mat-flat-button (click)="cancelModal($event)">Cancel</button>
        <button data-testid="confirm-button" mat-flat-button (click)="confirmModal($event)" class="confirm-button">Block Authentication</button>
    </mat-card-actions>
</ng-template>

<ng-template #unblockAuthentication>
    <mat-card-content>
        <div [innerHTML]="modalData.messageHTML"></div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button data-testid="cancel-button" mat-flat-button (click)="cancelModal($event)">Cancel</button>
        <button data-testid="confirm-button" mat-flat-button (click)="confirmModal($event)" class="confirm-button">Unblock Authentication</button>
    </mat-card-actions>
</ng-template>
