<div *ngIf="(fetching)" class="fetching-files">
    <mat-spinner></mat-spinner>
</div>
<div class="activity-container" id="activity-container">
    <div class="header-bar" id="header-bar">
        <div class="header-text-div">
            <span class="header-text" id="header-text">{{ activityTitle }}</span>
        </div>
        <div class="action-area" id="action-area">
            <div (click)="export()" class="upload-div"
                 id="upload-div"
            >
                <span class="material-symbols-outlined action-icon action-icon-margin"
                      id="export-span"
                >download</span>
                <span class="export-label" data-testid="export-button">Export Results</span>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="view-bar" id="view-bar">
            <app-filter
                (filterEvent)="filterTable('userType', $event)"
                [filterConfig]="userTypeFilterConfig"
                [selected]="userTypeFilterConfig.filterOptions[0]"
                data-testid="app-filter-allusers"
            ></app-filter>
            <app-search
                placeholder="Filter by user name..."
                data-testid="app-user-filter"
                (newSearchEvent)="performSearch($event)"
            ></app-search>
            <app-filter
                (filterEvent)="filterTable('feature', $event)"
                [filterConfig]="featureFilterConfig"
                data-testid="app-filter-feature"
            ></app-filter>
            <mat-form-field class="date-range">
                <mat-label>Date Range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate
                           [class.date-error]="range.controls.start.errors != null"
                           formControlName="start"
                           placeholder="MM/DD/YYYY"
                           (dateChange)="handleStartDateChangeEvent($event)">
                    <input matEndDate
                           [class.date-error]="range.controls.start.errors != null"
                           formControlName="end"
                           placeholder="MM/DD/YYYY"
                           (dateChange)="handleEndDateChangeEvent($event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">
                    Invalid start date
                </mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">
                    Invalid end date
                </mat-error>
            </mat-form-field>
        </div>
        <div class="table-container">
            <app-activity-table class="activity-table"
                                data-testid="app-activity-table"
                                [activityRecords]="activityRecords"
                                [searchText]="userSearchValue"
                                [pageSize]="pageSize"
                                [totalRecords]="totalRecords"
                                [fetching]="fetching"
                                (pageEvent)="handlePageEvent($event)"
                                (sortEvent)="handleSortChange($event)"
            ></app-activity-table>
        </div>
    </div>
</div>
