import { ModalConfigButtonClick, UserActionEnum } from './user.types';
import { ShareholderUserAccess } from './shareholder.types';

export type ModalConfigData = {
    title?: string;
    userName?: string;
    dealName?: string;
    messageHTML?: string;
    resetPwLink?: string;
    mfaMethodId?: string;
    mfaMethodName?: string;
    action?: UserActionEnum;
    id?: number;
    ids?: number[];
    email?: string;
    deactivateFromUserTable?: boolean;
    userAccessRecord?: ShareholderUserAccess;
};

export type ModalCloseConfig = {
    action: UserActionEnum;
    buttonClick: ModalConfigButtonClick;
    id?: number;
    ids?: number[];
};
